<script>
	import { v4 } from 'uuid';
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

	import Tooltip from '$lib/components/Misc/Tooltip.svelte';
	import InformationIcon from '../Icons/InformationIcon.svelte';

	export let large = undefined;
	export let value = '';
	export let id = `input-${v4()}`
	export let label = undefined;
	export let type = 'text';
	export let name = 'input';
	export let placeholder = '';
	export let inputRef = null;
	export let tabindex = undefined;
	export let minlength = '';
	export let maxlength = '';
	export let pattern = '';
	export let inputmode = '';
	export let min = ''; // Min Value for Number Input
	export let max = ''; // Max Value for Number Input
	export let step = '';
	export let removePlaceholderOnFocus = false;

	export let useBaseClass = true;
	export let pretext = undefined;
	export let pretextClass = '';
	export let usePretextSlot = false;
	export let useLargePretext = false;
	export let centerText = false;

	export let highlightIfBlank = false;

	export let tooltip = undefined;

	// Boolean HTML values
	export let required = false;
	export let readonly = false;
	export let disabled = false;
	export let autofocus = false;
	export let autocomplete = 'off';

	// Validation and blur behaviour
	export let isValid = true;

	export let isFocused = false;

	export const focusInput = (opts = undefined) => {
		/**
		 *  Opts:
		 * 	- click - click on input after focus
		 */

		inputRef?.focus();
		inputRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		if (opts?.click) inputRef.click();
		if (opts?.clear) value = undefined;
	};

	export const blurInput = () => {
		inputRef.blur();
	};

	export const clickInput = () => {
		inputRef.click();
	};

	export let validator = (val) => {
		return true;
	};

	export const blurBehaviour = (val) => {
		return;
	};

	export const setInputValue = (val) => {
		console.log('setInputValue() - ', val);
		value = val;
	};

	function setType(node) {
		node.type = type;
	}
</script>

<!-- <div class={$$props.class}> -->
<div class="form-group">
	{#if label}
		<label for={id} class="block text-sm font-medium">
			{#if tooltip}
				<Tooltip tooltipText={tooltip}>
					<div slot="element-with-tooltip">
						<div class="flex flex-row gap-1">
							<div>
								{label}
								{#if required}<span class="text-red-500">*</span>{/if}
							</div>
							<div class="grid content-center">
								<InformationIcon class="h-3 text-gray-700" />
							</div>
						</div>
					</div>
				</Tooltip>
			{:else}
				{label}
				{#if required}<span class="text-red-500">*</span>{/if}
			{/if}
		</label>
	{/if}
	<div class={label ? 'mt-1' : ''}>
		<div class="relative">
			<div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 text-center text-gray-500 {pretextClass}">
				{#if pretext}
					{pretext}
				{:else if usePretextSlot}
					<slot />
				{/if}
			</div>
			<!-- svelte-ignore a11y-autofocus -->
			<input
				use:setType
				{name}
				{id}
				{required}
				bind:value
				bind:this={inputRef}
				on:change={(e) => {
					isValid = validator(e.target.value);
					dispatch('change', { id: e.target.id, value: e.target.value, isValid });
				}}
				on:input={(e) => {
					isValid = validator(e.target.value);
					dispatch('input', { id: e.target.id, value: e.target.value, isValid });
				}}
				on:blur={(e) => {
					if (!autofocus) {
						inputRef.removeAttribute('autofocus');
					}
					isFocused = false;
					dispatch('blur', { id: e.target.id, value: e.target.value, isValid });
				}}
				on:click|stopPropagation={(e) => {
					dispatch('click', { id: e.target.id, value: e.target.value, isValid });
				}}
				on:focus|stopPropagation={(e) => {
					isFocused = true;
					dispatch('focus', { id: e.target.id, value: e.target.value, isValid });
				}}
				placeholder={removePlaceholderOnFocus && isFocused ? '' : placeholder}
				pettern={pattern}
				{tabindex}
				{inputmode}
				{min}
				{max}
				{minlength}
				{maxlength}
				{step}
				class="
					{useBaseClass ? (large ? 'input input-bordered w-full' : 'input input-bordered w-full ') : ''}
					{useBaseClass
					? pretext || usePretextSlot
						? centerText
							? 'form-input text-center'
							: `form-input-with-pretext ${useLargePretext ? 'with-pretext-lg' : ' with-pretext'}`
						: 'form-input'
					: ''} 
					{$$props.class} {!isValid && value != '' ? 'invalid-input' : ''}
					{disabled ? 'form-input-disabled cursor-not-allowed' : ''}
					{highlightIfBlank && (!value || value === '') ? 'invalid-input' : ''}
					"
				style={$$props.style}
				{autocomplete}
				{disabled}
				{readonly}
				{autofocus}
			/>
		</div>
	</div>
</div>
